.container {
  display: flex;
  flex-direction: column;
}
.row {
  /* flex: 1; */
  border: 1px solid #000;
}
.rowMain {
  flex: 1;
  border: 1px solid #74b9ff;
}
.rowMid {
  display: flex;
  flex-direction: row;
}
.rowMid > div {
  flex: 1;
  border: 1px solid gray;
}
.rowMid > div:hover,
.row:hover,
.rowMain:hover {
  border: 1px solid #74b9ff;
  color: #74b9ff;
  transform: scale(1.02);
}
.rowMid > div,
.row,
.rowMain {
  font-size: 12px;
  height: 16px !important;
}
