.bg-light-gray {
  background-color: #f7f7f7;
}
.table-calendar thead td,
.table-calendar thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-calendar td,
.table-calendar th {
  border: 1px solid #dee2e6;
}

.bg-sky.box-shadow {
  box-shadow: 0px 5px 0px 0px #00a2a7;
}

.bg-orange.box-shadow {
  box-shadow: 0px 5px 0px 0px #af4305;
}

.bg-green.box-shadow {
  box-shadow: 0px 5px 0px 0px #4ca520;
}

.bg-yellow.box-shadow {
  box-shadow: 0px 5px 0px 0px #dcbf02;
}

.bg-pink.box-shadow {
  box-shadow: 0px 5px 0px 0px #e82d8b;
}

.bg-purple.box-shadow {
  box-shadow: 0px 5px 0px 0px #8343e8;
}

.bg-lightred.box-shadow {
  box-shadow: 0px 5px 0px 0px #d84213;
}

.bg-sky {
  background-color: #02c2c7;
}

.bg-orange {
  background-color: #e95601;
}

.bg-green {
  background-color: #5bbd2a;
}

.bg-yellow {
  background-color: #f0d001;
}

.bg-pink {
  background-color: #ff48a4;
}

.bg-purple {
  background-color: #9d60ff;
}

.bg-lightred {
  background-color: #ff5722;
}

.padding-15px-lr {
  padding-left: 15px;
  padding-right: 15px;
}
.padding-5px-tb {
  padding-top: 5px;
  padding-bottom: 5px;
}
.margin-10px-bottom {
  margin-bottom: 10px;
}
.border-radius-5 {
  border-radius: 5px;
}

.margin-10px-top {
  margin-top: 10px;
}
.font-size14 {
  font-size: 14px;
}

.text-light-gray {
  color: #d6d5d5;
}
.font-size13 {
  font-size: 13px;
}

.table-calendar td,
.table-calendar th {
  border: 1px solid #dee2e6;
}

.table-calendar td:hover {
  cursor: pointer;
  background-color: #d2e3fc;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
