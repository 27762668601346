@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
body {
  font-family: 'Nunito', sans-serif;
  color: #000000;
  background-color: #fefefe;
  overflow-x: hidden; }

.layout_padding {
  padding: 90px 0; }

.layout_padding2 {
  padding: 75px 0; }

.layout_padding2-top {
  padding-top: 75px; }

.layout_padding2-bottom {
  padding-bottom: 75px; }

.layout_padding-top {
  padding-top: 90px; }

.layout_padding-bottom {
  padding-bottom: 90px; }

.layout_margin-top {
  margin-top: 90px; }

.layout_margin-bottom {
  margin-bottom: 90px; }

.heading_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .heading_container h2 {
    position: relative;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase; }
  .heading_container p {
    margin-top: 10px;
    margin-bottom: 0; }
  .heading_container.heading_center {
    align-items: center;
    text-align: center; }

a,
a:hover,
a:focus {
  text-decoration: none !important; }

a:hover,
a:focus {
  color: initial; }

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #dc3545; }

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none; }

/*header section*/
.hero_area {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .hero_area .hero_bg_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    z-index: -1; }
    .hero_area .hero_bg_box img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom right; }

.sub_page .hero_area {
  min-height: auto;
  background-color: #74b9ff; }
  .sub_page .hero_area .hero_bg_box {
    display: none; }

.header_section {
  padding: 15px 0; }
  .header_section .container-fluid {
    padding-right: 25px;
    padding-left: 25px; }

.navbar-brand span {
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
  text-transform: uppercase; }

.custom_nav-container {
  padding: 0; }
  .custom_nav-container .navbar-nav {
    margin-left: auto; }
    .custom_nav-container .navbar-nav .nav-item .nav-link {
      padding: 5px 20px;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      border-radius: 5px;
      transition: all 0.3s; }

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: #ffffff; }
  .custom_nav-container .nav_search-btn:hover {
    color: #74b9ff; }

.custom_nav-container .navbar-toggler {
  outline: none; }

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  transition: all 0.3s; }
  .custom_nav-container .navbar-toggler span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: #ffffff;
    margin: 7px 0;
    transition: all 0.3s;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s; }
    .custom_nav-container .navbar-toggler span::before, .custom_nav-container .navbar-toggler span::after {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      top: -10px;
      border-radius: 5px;
      transition: all 0.3s; }
    .custom_nav-container .navbar-toggler span::after {
      top: 10px; }
  .custom_nav-container .navbar-toggler[aria-expanded='true'] {
    transform: rotate(360deg); }
    .custom_nav-container .navbar-toggler[aria-expanded='true'] span {
      transform: rotate(45deg); }
      .custom_nav-container .navbar-toggler[aria-expanded='true'] span::before, .custom_nav-container .navbar-toggler[aria-expanded='true'] span::after {
        transform: rotate(90deg);
        top: 0; }

/*end header section*/
/* slider section */
.slider_section {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  padding: 45px 0 145px 0; }
  .slider_section .row {
    align-items: center; }
  .slider_section #customCarousel1 {
    width: 100%;
    position: unset; }
  .slider_section .detail-box {
    color: #0984e3; }
    .slider_section .detail-box h1 {
      font-size: 3rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
      color: #ffffff; }
    .slider_section .detail-box p {
      color: #fefefe;
      font-size: 14px; }
    .slider_section .detail-box .btn-box {
      display: flex;
      margin: 0 -5px;
      margin-top: 25px; }
      .slider_section .detail-box .btn-box a {
        margin: 5px;
        text-align: center;
        width: 165px; }
      .slider_section .detail-box .btn-box .btn1 {
        display: inline-block;
        padding: 10px 15px;
        background-color: #ffffff;
        color: #74b9ff;
        border-radius: 5px;
        transition: all 0.3s;
        border: 1px solid #ffffff; }
        .slider_section .detail-box .btn-box .btn1:hover {
          background-color: transparent;
          color: #ffffff; }
  .slider_section .img-box {
    display: flex;
    justify-content: center; }
    .slider_section .img-box img {
      width: 100%;
      max-width: 375px; }
  .slider_section .carousel-indicators {
    position: unset;
    margin: 0;
    justify-content: center;
    align-items: center; }
    .slider_section .carousel-indicators li {
      background-color: #ffffff;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      opacity: 1; }
      .slider_section .carousel-indicators li.active {
        width: 20px;
        height: 20px; }

.department_section {
  position: relative; }
  .department_section .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center; }
    .department_section .box .img-box {
      width: 90px;
      height: 90px;
      margin-bottom: 15px;
      background-color: #74b9ff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .department_section .box .img-box img {
        max-width: 55px;
        max-height: 55px;
        transition: all 0.3s; }
    .department_section .box .detail-box h5 {
      font-weight: bold;
      text-transform: uppercase; }
    .department_section .box .detail-box a {
      color: #0984e3;
      font-weight: 600; }
      .department_section .box .detail-box a:hover {
        color: #74b9ff; }
  .department_section .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 45px; }
    .department_section .btn-box a {
      display: inline-block;
      padding: 10px 45px;
      background-color: #0984e3;
      color: #ffffff;
      border-radius: 5px;
      transition: all 0.3s;
      border: 1px solid #0984e3; }
      .department_section .btn-box a:hover {
        background-color: transparent;
        color: #0984e3; }

.about_section .row {
  align-items: center; }

.about_section .img-box {
  display: flex;
  justify-content: center; }
  .about_section .img-box img {
    width: 100%;
    border-radius: 15px; }

.about_section .detail-box h3 {
  font-weight: bold; }

.about_section .detail-box p {
  margin-top: 15px; }

.about_section .detail-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #0984e3;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.3s;
  border: 1px solid #0984e3;
  margin-top: 15px; }
  .about_section .detail-box a:hover {
    background-color: transparent;
    color: #0984e3; }

.doctor_section .box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 45px;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  z-index: 1; }
  .doctor_section .box .img-box {
    width: 100%; }
    .doctor_section .box .img-box img {
      width: 100%;
      height: 50vh;
      object-fit: cover; }
  .doctor_section .box .detail-box {
    width: 100%;
    padding: 25px 15px;
    text-align: center;
    position: absolute;
    background: #ffffff;
    bottom: 0;
    opacity: 0.85; }
    .doctor_section .box .detail-box .social_box {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 180%;
      transform: translate(-50%, -50%);
      background-color: #74b9ff;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      opacity: 0;
      transition: all 0.3s; }
      .doctor_section .box .detail-box .social_box a {
        color: #ffffff;
        margin: 0 10px; }
        .doctor_section .box .detail-box .social_box a:hover {
          color: #ffffff; }
  .doctor_section .box:hover .social_box {
    top: 0;
    opacity: 1; }

.doctor_section .btn-box {
  display: flex;
  justify-content: center;
  margin-top: 45px; }
  .doctor_section .btn-box a {
    display: inline-block;
    padding: 10px 15px;
    background-color: #ffffff;
    color: #74b9ff;
    border-radius: 5px;
    transition: all 0.3s;
    border: 1px solid #ffffff; }
    .doctor_section .btn-box a:hover {
      background-color: transparent;
      color: #ffffff; }

.contact_section {
  position: relative; }
  .contact_section .heading_container {
    margin-bottom: 25px; }
    .contact_section .heading_container h2 {
      text-transform: uppercase; }
  .contact_section .form_container input {
    width: 100%;
    border: none;
    height: 50px;
    margin-bottom: 15px;
    padding-left: 20px;
    outline: none;
    color: #101010;
    background: #eeeeee; }
    .contact_section .form_container input::placeholder {
      color: #000000; }
    .contact_section .form_container input.message-box {
      height: 175px; }
  .contact_section .form_container button {
    width: 100%;
    border: none;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 55px;
    background-color: #0984e3;
    color: #ffffff;
    border-radius: 5px;
    transition: all 0.3s;
    border: 1px solid #0984e3; }
    .contact_section .form_container button:hover {
      background-color: transparent;
      color: #0984e3; }
  .contact_section .map_container {
    height: 368px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    padding: 0; }
    .contact_section .map_container .map {
      height: 100%;
      flex: 1; }
      .contact_section .map_container .map #googleMap {
        height: 100%; }

.client_section .heading_container {
  align-items: center; }

.client_section .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 45px;
  border-radius: 15px; }
  .client_section .box .img-box {
    margin-bottom: 15px;
    min-width: 145px;
    max-width: 145px;
    overflow: hidden; }
    .client_section .box .img-box img {
      width: 100%;
      border-radius: 100%;
      border: 7px solid rgba(255, 255, 255, 0.45); }
  .client_section .box .detail-box {
    display: flex;
    flex-direction: column; }
    .client_section .box .detail-box .name img {
      width: 25px;
      margin-bottom: 5px; }
    .client_section .box .detail-box .name h6 {
      color: #74b9ff;
      font-size: 20px; }

.client_section .carousel_btn-container {
  display: flex;
  justify-content: center; }

.client_section .carousel-control-prev,
.client_section .carousel-control-next {
  position: unset;
  width: 45px;
  height: 45px;
  border: none;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  background-color: #0984e3;
  background-position: center;
  border-radius: 5px;
  margin: 0 2.5px; }
  .client_section .carousel-control-prev:hover,
  .client_section .carousel-control-next:hover {
    background-color: #74b9ff; }

.client_section .carousel-control-next {
  left: initial; }

.footer_section {
  background: #74b9ff;
  color: #ffffff;
  padding: 60px 0 15px 0;
  position: relative; }
  .footer_section h4 {
    font-weight: 600;
    margin-bottom: 20px; }
  .footer_section .footer_col {
    margin-bottom: 30px; }
  .footer_section .footer_contact .contact_link_box {
    display: flex;
    flex-direction: column; }
    .footer_section .footer_contact .contact_link_box a {
      margin: 5px 0;
      color: #ffffff; }
      .footer_section .footer_contact .contact_link_box a i {
        margin-right: 5px; }
      .footer_section .footer_contact .contact_link_box a:hover {
        color: #74b9ff; }
  .footer_section .footer_social {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px; }
    .footer_section .footer_social a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      border-radius: 100%;
      margin-right: 10px;
      font-size: 24px; }
      .footer_section .footer_social a:hover {
        color: #74b9ff; }
  .footer_section .footer_links {
    display: flex;
    flex-wrap: wrap; }
    .footer_section .footer_links a {
      display: flex;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 15px;
      color: #ffffff; }
      .footer_section .footer_links a:hover {
        color: #74b9ff; }
  .footer_section form input {
    border: none;
    background-color: #fefefe;
    width: 100%;
    height: 45px;
    color: #000000;
    outline: none;
    border-radius: 5px;
    padding: 0 15px; }
    .footer_section form input::placeholder {
      color: #777; }
  .footer_section form button {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 10px 55px;
    background-color: #0984e3;
    color: #ffffff;
    border-radius: 5px;
    transition: all 0.3s;
    border: 1px solid #0984e3;
    margin-top: 15px; }
    .footer_section form button:hover {
      background-color: transparent;
      color: #0984e3; }
  .footer_section .footer-info {
    text-align: center; }
    .footer_section .footer-info p {
      color: #ffffff;
      padding: 25px 0;
      margin: 0; }
      .footer_section .footer-info p a {
        color: inherit; }

.container input :not([type='checkbox']) {
  background-color: #fefefe;
  width: 100%;
  height: 45px;
  color: #000000;
  outline: none;
  border-radius: 5px;
  padding: 0 15px; }
  .container input :not([type='checkbox'])::placeholder {
    color: #777; }

.react-calendar .react-calendar__tile--now {
  color: black;
  border: 1px solid #74b9ff; }

.react-calendar .customCalendarTile {
  color: black;
  border: 1px solid #f4c965; }

.react-calendar .react-calendar__tile--now {
  background-color: #ffffff;
  color: #000000; }

.react-calendar .react-calendar__tile--active {
  background-color: #006edc;
  color: #ffffff; }

.disabledTileBg {
  background-color: #f0f0f0; }

.treatmentBtn {
  cursor: pointer;
  border: 1px solid #74b9ff;
  color: #74b9ff;
  border-radius: 0.3rem;
  padding: 0.75rem; }
  .treatmentBtn.active {
    background-color: #74b9ff;
    color: #ffffff; }
  .treatmentBtn:hover {
    transform: scale(1.01);
    transition: all 0.3s; }

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #74b9ff; }

.react-calendar .react-calendar__tile--active {
  background-color: #74b9ff; }

.btn-outline-info {
  --cui-btn-border-color: $primary1;
  --cui-btn-color: $primary1;
  --cui-btn-hover-bg: $primary2;
  --cui-btn-hover-border-color: #47a3ff;
  --cui-btn-hover-color: #000015;
  --cui-btn-active-bg: $primary1;
  --cui-btn-active-border-color: #47a3ff;
  --cui-btn-active-color: #000015;
  --cui-btn-disabled-color: $primary1;
  --cui-btn-shadow: rgba(51, 153, 255, 0.5); }

.form-check-input {
  margin-top: 0.25em !important; }
