/* .spinner-container {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -4em;
  z-index: 1000;
} */
.spinner-container {
  /* position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; 
  z-index: 2000;
  opacity: 0.5;
  background: white; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background: white;
  opacity: 0.5;
  transition: opacity 0.2s;
}
