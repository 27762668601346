.selectedTile {
  background-color: #74b9ff !important;
}
/* .react-calendar .react-calendar__tile--active {
  background-color: #fff;
  color: black;
}

.react-calendar.react-calendar__tile--active > .selectedTile {
  background-color: #74b9ff !important;
} */

.removeIcon {
  margin-right: 10px;
}
.removeIcon :hover {
  cursor: pointer;
}
.dateContainer {
  /* max-height: 400px; */
  height: 50vh;
  overflow: overlay;
}
.timeContainer {
  /* max-height: 400px; */
  height: calc(50vh - 38px);
  overflow: overlay;
}
