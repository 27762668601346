.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.tooth {
  flex: 1;
  padding-inline: 5px;
}
